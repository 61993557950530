import { ExButton } from "../../components/ExButton";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export const ButtonWhatsApp = (props: any) => {
    return (
        <ExButton
            size="sm"
            icon={<WhatsAppIcon style={{ fontSize: '17px', marginTop: '-1px' }} />}
            color="#25D366"
            style={{ padding: '1px', height: '20px', width: '20px', borderRadius: '4px', marginLeft: '6px' }}
            onClick={() => window.open('https://wa.me/+55' + props.num.replace(/\D/g, ''), '_blank')}
        />
    )
}