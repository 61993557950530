import { isMobileDevice } from "../../commons/utils";
import { ConsultaPadrao } from "../../components/ConsultaPadrao";
import { model_colaborador_status } from "../../models";

export const ColaboradorStatus = () => {
    function drawColors(e: any) {
        return (
            <div style={{
                backgroundColor: e.colstatuscor,
                maxWidth: isMobileDevice() ? '100%' : '10px',
                width: '100%',
                height: isMobileDevice() ? '3px' : '30px',
                borderRadius: '2px'
            }} />
        )
    }

    const columns = [
        { field: 'colstatuscor', header: '', notSortable: true, body: (e: any) => drawColors(e), style: { width: '2rem' } },
        { field: 'colstatussequencia', header: 'Sequência' },
        { field: 'colstatusdescricao', header: 'Descrição' },
        { field: 'colstatusacao', header: 'Ação' },
        { field: 'colstatusativo', header: 'Ativo' },
        { field: 'functions', style: { minWidth: '2rem', width: '2rem' } }
    ];

    return (
        <ConsultaPadrao model={model_colaborador_status} columns={columns} sortField="colstatussequencia" />
    )
}