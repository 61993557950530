import { Route, Routes } from "react-router-dom"
import { RequireAuth, RequireAuthAdm, RequireAuthGestao } from "../contexts/AuthProvider/RequireAuth"
import { Usuario } from "../pages/Usuario"
import { Status } from "../pages/Status"
import { RequisicaoCampos } from "../pages/RequisicaoCampos"
import { Grupo } from "../pages/Grupo"
import { TipoMovimento } from "../pages/TipoMovimento"
import { Produto } from "../pages/Produto"
import { Cultura } from "../pages/Cultura"
import { ProdutoUnidadeMedida } from "../pages/ProdutoUnidadeMedida"
import { ProdutoUnidadeConversao } from "../pages/ProdutoUnidadeConversao"
import { MovimentoEstoqueCampos } from "../pages/MovimentoEstoqueCampos"
import { MovimentosPorPeriodo } from "../pages/Relatorios/MovimentosPorPeriodo"
import { Dashboard } from "../pages/Dashboard"
import { GrupoEmpresa } from "../pages/GrupoEmpresa"
import { Campos } from "../pages/Campos"
import { ValoresComplementares } from "../pages/ValoresComplementares"
import { RequisicoesKanban } from "../pages/RequisicoesKanban"
import { ColaboradorStatus } from "../pages/ColaboradorStatus"
import { ProdutoMedida } from "../pages/ProdutoMedida"
import { TipoMovimentoStatus } from "../pages/TipoMovimentoStatus"
import { Colaborador } from "../pages/Colaborador"
import { ColaboradorKanban } from "../pages/ColaboradorKanban"
import { TipoMovimentoProduto } from "../pages/TipoMovimentoProduto"

export const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<RequireAuth><Dashboard /></RequireAuth>} />
            <Route path="/usuario" element={<RequireAuthGestao><Usuario /></RequireAuthGestao>} />
            <Route path="/status" element={<RequireAuthAdm><Status /></RequireAuthAdm>} />
            <Route path="/camposrequisicao" element={<RequireAuthAdm><RequisicaoCampos /></RequireAuthAdm>} />
            <Route path="/grupo" element={<RequireAuthGestao><Grupo /></RequireAuthGestao>} />
            <Route path="/grupoempresa" element={<RequireAuthGestao><GrupoEmpresa /></RequireAuthGestao>} />
            <Route path="/tipomovimento" element={<RequireAuthAdm><TipoMovimento /></RequireAuthAdm>} />
            <Route path="/produto" element={<RequireAuthAdm><Produto /></RequireAuthAdm>} />
            <Route path="/cultura" element={<RequireAuthAdm><Cultura /></RequireAuthAdm>} />
            <Route path="/produtounidademedida" element={<RequireAuthAdm><ProdutoUnidadeMedida /></RequireAuthAdm>} />
            <Route path="/produtounidadeconversao" element={<RequireAuth><ProdutoUnidadeConversao /></RequireAuth>} />
            <Route path="/relatorio/movimentosporperiodo" element={<RequireAuth><MovimentosPorPeriodo /></RequireAuth>} />
            <Route path="/movimentoestoquecampos" element={<RequireAuthAdm><MovimentoEstoqueCampos /></RequireAuthAdm>} />
            <Route path="/campos" element={<RequireAuthAdm><Campos /></RequireAuthAdm>} />
            <Route path="/valorescomplementares" element={<RequireAuthAdm><ValoresComplementares /></RequireAuthAdm>} />
            <Route path="/requisicoes-kanban" element={<RequireAuth><RequisicoesKanban /></RequireAuth>} />
            <Route path="/colaboradorstatus" element={<RequireAuthAdm><ColaboradorStatus /></RequireAuthAdm>} />
            <Route path="/tipomovimento-status" element={<RequireAuthAdm><TipoMovimentoStatus /></RequireAuthAdm>} />
            <Route path="/produtomedida" element={<RequireAuth><ProdutoMedida /></RequireAuth>} />
            <Route path="/colaboradores" element={<RequireAuth><Colaborador /></RequireAuth>} />
            <Route path="/colaboradores-kanban" element={<RequireAuth><ColaboradorKanban /></RequireAuth>} />
            <Route path="/tipomovimento-produto" element={<RequireAuthAdm><TipoMovimentoProduto /></RequireAuthAdm>} />
        </Routes>
    )
}