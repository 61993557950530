export enum Privilegio {
    Normal = 1,
    Gestao = 2,
    Admnistrador = 999,
}

export interface IUser {
    usucodigo?: number;
    usuemail?: string;
    ususenha?: string;
    usunome?: string;
    token?: string;
    refresh?: string;
    versao?: string;
    grupo?: any;
    privilegio?: Privilegio;
    useAppRequisicoes?: any;
    useAppEstoques?: any;
    useAppDP?: any;
}

export interface IContext extends IUser {
    grupos: any;
    authenticate: (usuemail: string, ususenha: string, grupo?: any) => Promise<void>;
    logout: () => void;
    alterUser: (data: any) => void;
    get: (prefix: string, params?: any) => Promise<void>;
    post: (url: string, data: any, params?: any) => Promise<void>;
    setGruSuperiores: (grupos: any) => void;
    refreshGrupos: () => void;
}

export interface IAuthProvider {
    children: JSX.Element;
}