import { Api } from "../../api/adress";
import { IUser } from "./types";

function setLocalStorage(prefix: string, object: any) {
    localStorage.setItem(prefix, JSON.stringify(object));
}

function getLocalStorage(prefix: string) {
    const json = localStorage.getItem(prefix)

    if (!json) {
        return null;
    }

    const object = JSON.parse(json)

    return object ?? null;
}

export function setUserLocalStorage(user: IUser | null) {
    setLocalStorage('exalt_user', user);
}

export function setGrupoLocalStorage(grupo: any | null) {
    setLocalStorage('exalt_grupo', grupo);
}

export function getUserLocalStorage() {
    return getLocalStorage('exalt_user');
}

export function getGrupoLocalStorage() {
    return getLocalStorage('exalt_grupo');
}

export async function LoginRequest(usuemail: string, ususenha: string, gruidsuperior?: any) {
    try {
        const request = await Api.post('login', {
            usuemail: usuemail,
            ususenha: ususenha,
            gruidsuperior: gruidsuperior,
            aplicodigo: 3
        })

        return request;
    } catch (error: any) {
        return error
    }
}

export async function GetRequest(url: string, token: any, _params?: any) {
    const config = {
        headers: { 'Authorization': `Bearer ${token}` },
        params: _params
    }

    try {
        const response = await Api.get(url, config);
        return response;
    } catch (error: any) {
        return error
    }
}

export async function PostRequest(url: string, token: any, data: any, params?: any) {
    const config = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        params
    }

    try {
        const response = await Api.post(url, data, config);
        return response;
    } catch (error: any) {
        return error
    }
}