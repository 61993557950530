import { useEffect, useRef, useState } from "react"
import KanbanBoard from "../../components/Kanban/Kanban";
import { useAuth } from "../../contexts/AuthProvider/useAuth";
import { ButtonCard/*, formatMonetary*/, toastError } from "../../commons/utils";
import { Filtro } from "./Filtro";
import { ColaboradorInvalidacoes } from "../ColaboradorInvalidacoes";
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
//import moment from "moment";
import { useReactToPrint } from "react-to-print";

function createButton(hint: string, color: string, icon: JSX.Element, onClick: () => void) {
    return (
        <ButtonCard hint={hint} color={color} iconcenter={icon} onClick={onClick} className='p-1 me-1' />
    );
}

export const ColaboradorKanban = () => {
    const auth = useAuth();

    const refKanbam = useRef<any>();
    const refFiltro = useRef<any>();
    const refinvalidacoes = useRef<any>();
    const contentRef = useRef<HTMLDivElement>(null)
    const reactToPrintFn = useReactToPrint({ contentRef });

    const [status, setStatus] = useState<any>();
    const [htmlArq, setHtmlArq] = useState()

    function getNewStatus(idAtual: any, next: boolean) {
        const statusAtual = status.find((item: any) => item.id === idAtual);

        const candidatos = status.filter((item: any) => item.rejeitado === 0 && (next ? item.sequencia > statusAtual.sequencia : item.sequencia < statusAtual.sequencia));

        const statusAdjacente = candidatos.reduce((adjacente: any, atual: any) =>
            (!adjacente || (next ? atual.sequencia < adjacente.sequencia : atual.sequencia > adjacente.sequencia)) ? atual : adjacente, null
        );

        return statusAdjacente ? statusAdjacente.id : null;
    }

    useEffect(() => {
        auth.get('/v1/colaboradorstatus')
            .then((response: any) => {
                const filteredData = response.data.filter((item: any) => item.colstatusativo === 1).sort((a: any, b: any) => a.colstatussequencia - b.colstatussequencia);

                const _status = filteredData.map((item: any) => ({
                    id: item.colstatusid,
                    title: item.colstatusdescricao,
                    color: item.colstatuscor,
                    value: item.colstatusid,
                    sequencia: item.colstatussequencia,
                    rejeitado: item.colstatusrejeicao
                }));

                setStatus(_status);
            })
            .catch((error: any) => {
                toastError(error);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function updateStatus(data: any, next: boolean) {
        data.colstatusid = getNewStatus(data.colstatusid, next);

        await auth.post('/v1/colaboradorstatuscrm', { colstatusid: data.colstatusid }, { colid: data.colid })
            .then(() => {
                refFiltro.current.refresh();
            })
            .catch((error: any) => {
                console.log(error)
            })
    }

    useEffect(() => {
        if (htmlArq) {
            reactToPrintFn()
            setHtmlArq(undefined)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [htmlArq])

    /*const generatePDF = async (reqid: number, usucodigo: number) => {
        const getHistoricoStatus = () => ''

        auth.get('/v1/requisicaorelatorio').then((resp: any) => {
            auth.get('/v1/requisicaopdf', { params: { reqid, usucodigo } })
                .then((response: any) => {
                    // eslint-disable-next-line no-new-func
                    const html = new Function('moment', 'formatFloat', 'getHistoricoStatus', `return \`${resp.data[0].reqrelcorpo}\``).call({
                        ...response.data,
                        date: moment().format('DD/MM/YYYY HH:mm:ss'),
                    }, moment, formatMonetary, getHistoricoStatus)

                    setHtmlArq(html)
                })
                .catch((error: any) => {
                    toastError(error);
                })
        })
    }*/

    function onGetData(data: any) {
        const newArray: any = [];
        refKanbam.current.setTasks([]);

        const sequencias = status.map((item: any) => item.sequencia);

        const menorSequencia = Math.min(...sequencias);
        const maiorSequencia = Math.max(...sequencias);

        data.forEach((item: any) => {

            const { colid, grudescricao, colnome, colcpf, colstatusid, colstatussequencia, colstatusrejeicao } = item;

            const id = colid;
            const content =
                <div style={{ paddingTop: '5px' }}>
                    {colnome && <div style={{ paddingBottom: '5px' }}>{colnome}</div>}
                    {colcpf && <div style={{ fontSize: '12px', paddingBottom: '7px' }}>{colcpf}</div>}
                </div>
            const columnId = colstatusid;
            const details = grudescricao;
            const color = status.find((_status: any) => _status.value === colstatusid)?.color;

            const functions = (
                <>
                    {createButton('Invalidar', color, <PlaylistRemoveIcon />, () => refinvalidacoes.current.openModal(item))}

                    {colstatusrejeicao !== 1 && (
                        <>
                            {colstatussequencia !== menorSequencia &&
                                createButton('Retroceder Status', color, <UndoIcon />, () => updateStatus(item, false))}

                            {colstatussequencia !== maiorSequencia &&
                                createButton('Avançar Status', color, <RedoIcon />, () => updateStatus(item, true))}
                        </>
                    )
                    }
                    {/* {createButton('Imprimir', color, <FaPrint style={{ fontSize: '16px' }} />, () => generatePDF(colid))} */}
                </>
            );

            newArray.push({ id, content, columnId, details, undefined, functions });
        });

        refKanbam.current.setTasks(newArray);
    }

    function onSuccess() {
        refFiltro.current.refresh();
    }

    return (
        status &&
        <>
            <ColaboradorInvalidacoes ref={refinvalidacoes} onSuccess={onSuccess} />
            <Filtro ref={refFiltro} onGetData={onGetData} />
            <KanbanBoard ref={refKanbam} columnsId={status?.map((item: any) => item.id)} status={status} />
        </>
    )
}