import { createContext, useState, useEffect } from 'react';
import { IAuthProvider, IContext, IUser } from './types';
import { GetRequest, PostRequest, LoginRequest, getUserLocalStorage, setUserLocalStorage, getGrupoLocalStorage, setGrupoLocalStorage } from './util';
import { toastError } from '../../commons/utils';

export const AuthContext = createContext<IContext>({} as IContext);

export const AuthProvider = ({ children }: IAuthProvider) => {
    const [user, setUser] = useState<IUser | null>();
    const [grupos, setGrupos] = useState<any | null>();

    useEffect(() => {
        const user = getUserLocalStorage();
        const grupos = getGrupoLocalStorage();

        if (user)
            setUser(user);

        if (grupos)
            setGrupos(grupos);
    }, []);

    async function setGruSuperiores(grupos: any) {
        setGrupos({ grupos });
        setGrupoLocalStorage({ grupos });
    }

    async function alterUser(data: any) {
        let payload: IUser = { ...data };

        await GetRequest('/v1/aplicativotouser', data.token, { usucodigo: data.usucodigo })
            .then((response: any) => {
                payload.useAppRequisicoes = !!(response.data.find((app: any) => app.aplicodigo === 1 && app.apliusuativo === 1));
                payload.useAppEstoques = !!(response.data.find((app: any) => app.aplicodigo === 2 && app.apliusuativo === 1));
                payload.useAppDP = !!(response.data.find((app: any) => app.aplicodigo === 4 && app.apliusuativo === 1));
            })

        setUser(payload);
        setUserLocalStorage(payload);
    }

    async function refreshGrupos() {
        get('/v1/grupoonlyuser?grutipo=0')
            .then((response: any) => {
                setGruSuperiores(response.data);
            })
            .catch((error: any) => {
                toastError(error);
            })
    }

    async function authenticate(usuemail: string, ususenha: string, grupo?: any) {
        const response = await LoginRequest(usuemail, ususenha, grupo?.gruid);

        if (response.status && [200, 201].includes(response.status)) {
            const { token, refresh, apiuser } = response.data;
            const { usucodigo, usunome, _versao, privcrmid } = apiuser;

            if (grupo) {
                await alterUser({ usuemail, ususenha, grupo, token, refresh, usucodigo, usunome, versao: _versao, privilegio: privcrmid ?? 1 });
            } else {
                return token;
            }
        } else {
            throw response;
        }
    }

    function logout() {
        setUser(null);
        setUserLocalStorage(null);
    }

    async function get(prefix: string, params?: any) {
        const response = await GetRequest(prefix, user?.token, params);

        if ((response.response) && (response.response.status === 406)) {
            logout();
            return response
        }

        if (response.status && ((response.status === 200) || (response.status === 201))) {
            return response
        } else
            throw response;
    }

    async function post(url: string, data: any, params?: any) {
        const response = await PostRequest(url, user?.token, data, params);

        if (response.status && ((response.status === 200) || (response.status === 201))) {
            return response
        } else
            throw response;
    }

    return (
        <AuthContext.Provider value={{ ...user, ...grupos, authenticate, logout, alterUser, get, post, setGruSuperiores, refreshGrupos }}>
            {children}
        </AuthContext.Provider>
    )
}