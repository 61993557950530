import { Grid } from "@mui/material";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { formatDataCompare, formatMonetary, isMobileDevice } from "../../../commons/utils";
import BarChart from "../../../charts/Bar";
import { Input } from "../../../components/Input";
import PieChart from "../../../charts/pie";
import moment from "moment";
import { GridCard } from "..";
import { ExCard } from "../../../charts/Card";
import { FaSackDollar } from "react-icons/fa6";
import { FaCheckDouble } from "react-icons/fa6";
import { FaRegQuestionCircle } from "react-icons/fa";

const DateInput = forwardRef(({ id, label, onChange, defaultValue }: any, ref) => (
    <Input ref={ref} id={id} type="date" label={label} onAfterChange={onChange} defaultValue={defaultValue} />
));

export const DashRequisicoes = forwardRef((props: any, ref) => {
    const [originData, setOriginData] = useState<any[]>([]);

    const refemissaoi = useRef<any>();
    const refemissaof = useRef<any>();
    const refchartimobilizado = useRef<any>();
    const refchartcatimobilizado = useRef<any>();
    const refchartfornecedores = useRef<any>();
    const refchartusuarios = useRef<any>();
    const refchartmensal = useRef<any>();
    const refcardqtde = useRef<any>();
    const refcardtotal = useRef<any>();
    const refcardsemcat = useRef<any>();

    useImperativeHandle(ref, () => ({
        setData
    }));

    const getNextMultipleOf1000 = (value: number) => Math.ceil(value / 1000) * 1000;

    const groupData = (data: any[], groupByKey: string | ((item: any) => string)) =>
        data.reduce((acc: Record<string, number>, item: any) => {
            const key = typeof groupByKey === 'function' ? groupByKey(item) : item[groupByKey] || 'Não Informado';
            const value = parseFloat((item.total || 0).toFixed(2));
            acc[key] = (acc[key] || 0) + value;
            return acc;
        }, {});

    const populateChart = (refChart: any, data: any, options: { groupByKey: any; chartTitle: string; limit?: number; type?: 'Bar' | 'Pie'; labelFontSize?: string }) => {
        const { groupByKey, chartTitle, limit = Infinity, type = 'Bar', labelFontSize = '16px' } = options;

        const groupedData = groupData(data, groupByKey);

        let entries: [string, number][];

        if (typeof groupByKey === 'function')
            entries = Object.entries(groupedData).sort(([keyA], [keyB]) => {
                const dateA = moment(keyA, 'MM/YYYY');
                const dateB = moment(keyB, 'MM/YYYY');
                return dateA.diff(dateB);
            }).slice(0, limit);
        else
            entries = Object.entries(groupedData).sort(([, a], [, b]) => b - a).slice(0, limit);

        const labels = entries.map(([key]) => key);
        const values = entries.map(([, value]) => value);

        if (type === 'Bar') {
            refChart?.current.setOptions(labels, getNextMultipleOf1000(Math.max(...values)), chartTitle, undefined, undefined, undefined, labelFontSize);
            refChart?.current.setSeries([{ name: chartTitle, data: values }]);
        } else if (type === 'Pie') {
            refChart?.current.setLabels(labels);
            refChart?.current.setSeries(values);
        }
    };

    const setCardValues = (ref: any, value: any) => {
        ref?.current.setValue(value);
    };

    const filterByDate = (data: any[], startDate?: any, endDate?: any) =>
        data.filter((item: any) => {
            const date = formatDataCompare(item.reqdata);
            return (!startDate || date >= startDate) && (!endDate || date <= endDate);
        });

    const prePopulate = (datachart: any, startDate?: any, endDate?: any) => {
        const filteredData = filterByDate(datachart, startDate, endDate);

        setCardValues(refcardqtde, filteredData.length || 0);
        setCardValues(refcardtotal, formatMonetary(filteredData.reduce((sum: any, item: any) => sum + item.total, 0)));
        setCardValues(refcardsemcat, formatMonetary(
            filteredData.filter((item: any) => !item.imobcatdescricao).reduce((sum: any, item: any) => sum + item.total, 0)
        ));

        const categorizedData = filteredData.filter((item: any) => item.imobcatdescricao);

        populateChart(refchartcatimobilizado, categorizedData, {
            groupByKey: 'imobcatdescricao',
            chartTitle: 'Valor por Categoria de Imobilizado',
        });

        populateChart(refchartmensal, categorizedData, {
            groupByKey: (item: any) => moment(item.reqdata).format('MM/YYYY'),
            chartTitle: 'Valor por Mês',
        });

        populateChart(refchartimobilizado, categorizedData, {
            groupByKey: 'imobdescricao',
            chartTitle: 'Top 10 - Valor por Imobilizado',
            limit: 10,
        });

        populateChart(refchartfornecedores, categorizedData, {
            groupByKey: 'fornome',
            chartTitle: 'Top 10 - Fornecedores',
            limit: 10,
            type: 'Pie',
        });

        populateChart(refchartusuarios, categorizedData, {
            groupByKey: 'usunome',
            chartTitle: 'Top 5 - Usuários',
            limit: 5,
            type: 'Pie',
        });
    };

    const setData = (_data: any) => {
        setOriginData(_data);
        prePopulate(_data, refemissaoi.current.getValue(), refemissaof.current.getValue());
    };

    return (
        <Grid container spacing={2} sx={{ padding: isMobileDevice() ? '1rem' : '1rem 8rem' }}>
            <Grid item xs={12} sx={{ paddingTop: '0px !important' }}>
                <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Grid item xs={isMobileDevice() ? 12 : 2}>
                        <DateInput ref={refemissaoi} id="dash_req_emis_inicial" label="Emissão Inicial" onChange={(value: any) => prePopulate(originData, value, refemissaof.current.getValue())} defaultValue={new Date()} />
                    </Grid>
                    <Grid item xs={isMobileDevice() ? 12 : 2}>
                        <DateInput ref={refemissaof} id="dash_req_emis_final" label="Emissão Final" onChange={(value: any) => prePopulate(originData, refemissaoi.current.getValue(), value)} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={isMobileDevice() ? 12 : 8}>
                <BarChart ref={refchartimobilizado} />
            </Grid>
            <Grid item xs={isMobileDevice() ? 12 : 4}>
                <div style={{ height: '450px' }}>
                    <PieChart ref={refchartfornecedores} title='Top 10 - Fornecedores' monetary />
                </div>
            </Grid>
            <Grid item xs={isMobileDevice() ? 12 : 8}>
                <BarChart ref={refchartcatimobilizado} />
            </Grid>
            <Grid item xs={isMobileDevice() ? 12 : 4}>
                <div style={{ height: '450px' }}>
                    <PieChart ref={refchartusuarios} title='Top 5 - Usuários' monetary />
                </div>
            </Grid>
            <Grid item xs={isMobileDevice() ? 12 : 8}>
                <BarChart ref={refchartmensal} />
            </Grid>
            <Grid item xs={isMobileDevice() ? 12 : 4}>
                <Grid container spacing={2}>
                    <GridCard md={12}>
                        <ExCard ref={refcardqtde} title='Quantidade Total' icon={<FaCheckDouble />} />
                    </GridCard>
                    <GridCard md={12}>
                        <ExCard ref={refcardtotal} title='Valor Total' icon={<FaSackDollar />} />
                    </GridCard>
                    <GridCard md={12}>
                        <ExCard ref={refcardsemcat} title='Total sem Categoria' icon={<FaRegQuestionCircle />} />
                    </GridCard>
                </Grid>
            </Grid>
        </Grid>
    );
});
