import { FaUserLock } from "react-icons/fa";
import { FaBuildingWheat, FaPeopleGroup } from "react-icons/fa6";
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import { FaBoxOpen } from "react-icons/fa6";
import { GiLindenLeaf } from "react-icons/gi";
import { TbRulerMeasure } from "react-icons/tb";
import { BsInputCursor } from "react-icons/bs";
import { TbReportAnalytics } from "react-icons/tb";
import { camelize, getApiCEP, getApiCNPJ } from "../commons/utils";
import { FaBuildingCircleCheck } from "react-icons/fa6";

export const options_sim_nao = [
    { value: 0, label: 'Não' },
    { value: 1, label: 'Sim' }
]

const options_sexo = [
    { value: 0, label: 'Masculino' },
    { value: 1, label: 'Feminino' },
    { value: 2, label: 'Outro' }
]

const options_tipo_pessoa = [
    { value: 0, label: 'Física' },
    { value: 1, label: 'Jurídica' },
    { value: 2, label: 'Estrangeira' }
]

const options_acao_valcomplementares = [
    { value: 0, label: 'Nenhuma' },
    { value: -1, label: 'Subtrair' },
    { value: 1, label: 'Somar' }
]

const options_formato_valcomplementares = [
    { value: 0, label: 'Valor' },
    { value: 1, label: 'Percentual' },
    { value: 2, label: 'X Volumes' }
]

export const options_deficiente = [
    { value: 0, label: 'Não possui' },
    { value: 1, label: 'Física' },
    { value: 2, label: 'Visual' },
    { value: 3, label: 'Auditiva' },
    { value: 4, label: 'Mental' },
    { value: 5, label: 'Intelectual' },
    { value: 99, label: 'Outros' }
];

export const options_cor_etinia = [
    { value: 0, label: 'Não informado' },
    { value: 1, label: 'Branca' },
    { value: 2, label: 'Parda' },
    { value: 3, label: 'Negra' },
    { value: 4, label: 'Amarela' },
    { value: 5, label: 'Indígena' }
];

export const options_estadocivil = [
    { value: 0, label: 'Não informado' },
    { value: 1, label: 'Solteiro' },
    { value: 2, label: 'Casado' },
    { value: 3, label: 'Viúvo' },
    { value: 4, label: 'Divorciado' },
    { value: 5, label: 'Concubinato' },
    { value: 6, label: 'Separado' },
    { value: 7, label: 'Judicialmente' },
    { value: 8, label: 'União estável' }
];

export const options_escolaridade = [
    { value: 0, label: 'Não informado' },
    { value: 1, label: 'Analfabeto' },
    { value: 2, label: '1ª a 4ª série incompleta' },
    { value: 3, label: '4ª série completa' },
    { value: 4, label: '5ª a 8ª série incompleta' },
    { value: 5, label: '1º grau completo' },
    { value: 6, label: '2º grau incompleto' },
    { value: 7, label: '2º grau completo' },
    { value: 8, label: 'Superior incompleto' },
    { value: 9, label: 'Superior completo' }
];

export const model_usuario = {
    icon: <FaUserLock />,
    name: 'Usuário',
    key: 'usucodigo',
    description_field: 'usunome',
    inactive_field: 'usuativo',
    api_get: '/v1/usuariocrm',
    api_post: '/v1/usuarioone',
    inputs: [
        { id: 'usuemail', label: 'E-mail', type: 'text', md: 12, required: true, model: 'email' },
        { id: 'usunome', label: 'Nome', type: 'text', md: 12, required: false },
    ],
    base: {
        usunome: ''
    }
}

export const model_status = {
    name: 'Status',
    key: 'statid',
    description_field: 'statdescricao',
    inactive_field: 'statativo',
    api_get: '/v1/status',
    api_post: '/v1/statusone',
    inputs: [
        { id: 'statid', label: 'Código', type: 'text', md: 3, required: true, disabled: true },
        { id: 'statdescricao', label: 'Descrição', type: 'text', md: 9, required: true },
        { id: 'statsequencia', label: 'Sequência', type: 'number', md: 3, required: true },
        { id: 'statacao', label: 'Ação', type: 'text', md: 9, required: true },
        { id: 'statgerapdf', label: 'Gera PDF', type: 'check', md: 3 },
        { id: 'statassinatura', label: 'Assinatura', type: 'check', md: 3 },
    ],
    base: {
        statativo: 1
    }
}

export const model_campos = {
    name: 'Campos',
    key: 'campnome',
    consider_key: true,
    description_field: 'campdescricao',
    api_get: '/v1/campos',
    api_post: '/v1/campo',
    inputs: [
        { id: 'campnome', label: 'Código', type: 'text', md: 5, required: true, disableEdit: true },
        { id: 'campdescricao', label: 'Descrição', type: 'text', md: 7, required: true },
        { id: 'campmsgerro', label: 'Mensagem Erro', type: 'text', md: 12, required: true }
    ],
    base: {
    }
}

export const model_requisicao_campos = {
    name: 'Campos Requisição',
    key: 'none',
    description_field: 'reqcamponome',
    inactive_field: 'reqcampoativo',
    api_get: '/v1/requisicaocamposstatus',
    api_post: '/v1/requisicaocamposone',
    inputs: [
        { id: 'reqcamponome', label: 'Campo', type: 'text', md: 12, required: true },
        { id: 'reqcamposequencia', label: 'Sequência', type: 'text', md: 3, required: true },
        { id: 'statid', label: 'A Partir do Status', type: 'select', md: 9, model: model_status },
        { id: 'reqcampoobrigatorio', label: 'Obrigatório', type: 'check', md: 3 },
    ],
    base: {
        reqcampoativo: 1
    }
}

export const model_grupo_consulta = {
    name: 'Grupo',
    key: 'gruid',
    description_field: 'grudescricao',
    api_get: '/v1/grupoonlyuser',
}

export const model_grupo = {
    icon: <FaBuildingWheat />,
    name: 'Grupo',
    key: 'gruid',
    description_field: 'grudescricao',
    api_get: '/v1/grupoonlyuser',
    api_post: '/v1/newgrupo',
    inputs: [
        { id: 'gruid', label: 'Código', type: 'number', md: 3, disabled: true, required: true },
        { id: 'grudescricao', label: 'Descrição', type: 'text', md: 9, required: true },
        { id: 'gruidpai', label: 'Grupo Pai', type: 'select', md: 12, model: model_grupo_consulta }
    ],
    base: {
        gruativo: 1,
        grutipo: 0
    }
}

export const model_aplicativo_usuario = {
    icon: <PhonelinkSetupIcon />,
    name: 'Liberação de Aplicativos',
    key: 'aplicodigo',
    description_field: 'aplinome',
    api_get: '/v1/aplicativotouser',
}

export const model_cultura = {
    icon: <GiLindenLeaf />,
    name: 'Cultura',
    key: 'cultid',
    description_field: 'cultdescricao',
    api_get: '/v1/cultura',
    api_post: '/v1/culturacrm',
    inputs: [
        { id: 'cultid', label: 'Código', type: 'text', md: 12, disabled: true, required: true },
        { id: 'cultdescricao', label: 'Descrição', type: 'text', md: 12, required: true }
    ],
    base: {}
}

export const model_cultura_etapa = {
    name: 'Cultura Etapa',
    key: 'cultetid',
    description_field: 'cultetdescricao',
    api_get: '/v1/culturaetapa',
    inputs: [
        { id: 'cultetid', label: 'Código', type: 'text', md: 12, disabled: true, required: true },
        { id: 'cultetdescricao', label: 'Descrição', type: 'text', md: 12, required: true }
    ],
    base: {}
}

export const model_cultura_medida = {
    name: 'Cultura Medida',
    key: 'cultmedcontador',
    description_field: 'cultmeddescricao',
    api_get: '/v1/culturamedida',
    inputs: [
        { id: 'cultmedcontador', label: 'Código', type: 'text', md: 12, disabled: true, required: true },
        { id: 'cultmeddescricao', label: 'Descrição', type: 'text', md: 12, required: true }
    ],
    base: {}
}

export const model_produto_unidademedida = {
    icon: <TbRulerMeasure />,
    name: 'Unidade Medida',
    key: 'produnidid',
    description_field: 'produniddescricao',
    inactive_field: 'produnidativo',
    api_get: '/v1/produtounidademedida',
    api_post: '/v1/produtounidademedidacrm',
    inputs: [
        { id: 'produnidid', label: 'Código', type: 'text', md: 12, disabled: true, required: true },
        { id: 'produniddescricao', label: 'Descrição', type: 'text', md: 12, required: true }
    ],
    base: {
        produnidativo: 1
    }
}

function changeCultura(_newValue: any, refs: any) {
    if (_newValue)
        refs.current['cultetid'].setFilter({ cultid: _newValue.value })
    else
        refs.current['cultetid'].clearFilter();
}

export const model_produto = {
    icon: <FaBoxOpen />,
    name: 'Produto',
    key: 'prodid',
    description_field: 'proddescricao',
    inactive_field: 'prodativo',
    api_get: '/v1/produtocrm',
    api_post: '/v1/produtocrm',
    inputs: [
        { id: 'prodid', label: 'Código', type: 'text', md: 12, disabled: true, required: true },
        { id: 'proddescricao', label: 'Descrição', type: 'text', md: 12, required: true },
        { id: 'cultid', label: 'Cultura', type: 'select', model: model_cultura, md: 12, required: true, onAfterChange: changeCultura },
        { id: 'produnididpadrao', label: 'Unidade Padrão', type: 'select', model: model_produto_unidademedida, md: 6, required: true },
        { id: 'produnididminima', label: 'Unidade Mínima', type: 'select', model: model_produto_unidademedida, md: 6, required: true },
        { id: 'cultetid', label: 'Etapa', type: 'select', model: model_cultura_etapa, md: 12, required: true },
        { id: 'produsacategoria', label: 'Usa Categoria', type: 'check', md: 4 },
        { id: 'produsatamanho', label: 'Usa Tamanho', type: 'check', md: 4 },
    ],
    base: {
        prodativo: 1
    }
}

export const model_tipo_movimento = {
    icon: <HiOutlineSwitchHorizontal />,
    name: 'Tipo Movimento',
    key: 'tipmovcontador',
    description_field: 'tipmovdescricao',
    sub_description_field: 'produtodesc',
    inactive_field: 'tipmovativo',
    api_get: '/v1/tipomovivmentocrm',
    api_post: '/v1/tipomovimentocrm',
    inputs: [
        { id: 'tipmovcontador', label: 'Código', type: 'number', md: 3, disabled: true, required: true },
        { id: 'tipmovdescricao', label: 'Descrição', type: 'text', md: 9, required: true },
        { id: 'tipmovacao', label: 'Ação', type: 'text', md: 12, required: true },
        { id: 'tipmovperda', label: 'Perda', type: 'check', md: 3 },
        { id: 'tipmovinformapreco', label: 'Informa Preço', type: 'check', md: 4 },
        { id: 'tipmovfinanceiro', label: 'Gera Financeiro', type: 'check', md: 5 },
    ],
    base: {
        tipmovativo: 1
    }
}

export const model_tipo_movimento_produto = {
    icon: <HiOutlineSwitchHorizontal />,
    name: 'Tipo de Movimentação de Produtos',
    key: 'tipmovprodid',
    inactive_field: 'tipmovprodativo',
    api_get: '/v1/tipomovivmentoprodutocrm',
    api_post: '/v1/tipomovimentoproduto',
    inputs: [
        { id: 'tipmovcontador', label: 'Tipo de Movimento', type: 'select', model: model_tipo_movimento, md: 12, required: true },
        { id: 'prodidentrada', label: 'Produto Entrada', type: 'select', model: model_produto, md: 6, required: false },
        { id: 'prodidsaida', label: 'Produto Saída', type: 'select', model: model_produto, md: 6, required: false },
    ],
    base: {
        tipmovprodativo: 1
    }
}

export const model_produto_unidade_conversao = {
    name: 'Unidade Conversão',
    api_get: '/v1/produtounidadeconversaocrm',
    api_post: '/v1/produtounidadeconversaocrm',
    inputs: [
        { id: 'produnidid', label: 'Unidade Medida', type: 'select', model: model_produto_unidademedida, md: 12, required: true },
        { id: 'produnididbase', label: 'Unidade Medida Base', type: 'select', model: model_produto_unidademedida, md: 12, required: true },
        { id: 'produnidconvfator', label: 'Fator Conversão', type: 'number', md: 6, required: true }
    ],
    base: {}
}

export const model_tipo_movimento_status = {
    name: 'Status',
    key: 'tipmovstid',
    description_field: 'tipmovstdescricao',
    inactive_field: 'tipmovstativo',
    api_get: '/v1/tipomovimentostatuscrm',
    api_post: '/v1/tipomovimentostatuscrm',
    inputs: [
        { id: 'tipmovstid', label: 'Código', type: 'text', md: 3, required: true, disabled: true },
        { id: 'tipmovstdescricao', label: 'Descrição', type: 'text', md: 9, required: true, maxLength: 30 },
        { id: 'tipmovstsequencia', label: 'Sequência', type: 'number', md: 3, required: true },
        { id: 'tipmovstacao', label: 'Ação', type: 'text', md: 9, required: true, maxLength: 30 },
        { id: 'tipmovcontador', label: 'Tipo Movimento', type: 'select', model: model_tipo_movimento, md: 12, required: true },
        { id: 'tipmovstcor', label: 'Cor', type: 'color', md: 12 },
        { id: 'tipmovstmovimenta', label: 'Movimenta Estoque', type: 'check', md: 12, defaultValue: 1 }
    ],
    base: {
        tipmovstativo: 1
    }
}

export const model_colaborador_status = {
    name: 'Status',
    key: 'colstatusid',
    description_field: 'colstatusdescricao',
    inactive_field: 'colstatusativo',
    api_get: '/v1/colaboradorstatus',
    api_post: '/v1/colaboradorstatus',
    inputs: [
        { id: 'colstatusid', label: 'Código', type: 'text', md: 3, required: true, disabled: true },
        { id: 'colstatusdescricao', label: 'Descrição', type: 'text', md: 9, required: true, maxLength: 30 },
        { id: 'colstatussequencia', label: 'Sequência', type: 'number', md: 3, required: true },
        { id: 'colstatusacao', label: 'Ação', type: 'text', md: 9, required: true, maxLength: 30 },
        { id: 'colstatuscor', label: 'Cor', type: 'color', md: 12 },
        { id: 'colstatusrejeicao', label: 'Rejeição', type: 'check', md: 12 },
    ],
    base: {
        colstatusativo: 1
    }
}

export const model_movimentoestoque_campos = {
    icon: <BsInputCursor />,
    name: 'Campos Movimento Estoque',
    key: 'none',
    description_field: 'movestcamponome',
    inactive_field: 'movestcampoativo',
    api_get: '/v1/movimentoestoquecamposcrm',
    api_post: '/v1/movimentoestoquecamposone',
    inputs: [
        { id: 'movestcampoobrigatorio', label: 'Obrigatório', type: 'check', md: 12 },
        { id: 'movestcamposequencia', label: 'Sequência', type: 'number', md: 3, required: true, disableEdit: true },
        { id: 'movestcamponome', label: 'Campo', type: 'select', md: 9, model: model_campos, required: true, disableEdit: true },
        { id: 'tipmovcontador', label: 'Tipo Movimento', type: 'select', model: model_tipo_movimento, md: 12, required: true },
        { id: 'tipmovstid', label: 'A Partir do Status', type: 'select', md: 12, model: model_tipo_movimento_status },
    ],
    base: {
        movestcampoativo: 1
    }
}

export const model_plantio = {
    name: 'Plantio',
    key: 'plid',
    description_field: 'pldescricao',
    api_get: '/v1/plantio'
}

export const model_movimentos_por_periodo = {
    icon: <TbReportAnalytics />,
    name: 'Movimentos Por Período',
    api_get: '/v1/movimentosporperiodo'
}

function changeTipoPessoa(_newValue: any, refs: any) {
    refs.current['empcpfcnpj'].setLabel(_newValue.value === 0 ? 'CPF' : _newValue.value === 1 ? 'CNPJ' : 'Documento');
    refs.current['empcpfcnpj'].setModel(_newValue.value === 0 ? 'cpf' : _newValue.value === 1 ? 'cnpj' : '');
}

async function changeCNPJ(_newValue: any, refs: any) {
    if ((_newValue.length === 18) && (refs.current['emptipopessoa'].getValue() === 1)) {
        refs.current['empcpfcnpj'].setMsgInfo('Pesquisando CNPJ...');

        await getApiCNPJ(_newValue)
            .then((data: any) => {
                refs.current['empie'].setValue(camelize(data.estabelecimento?.inscricoes_estaduais[0]?.inscricao_estadual));
                refs.current['empnome'].setValue(camelize(data.razao_social));
                refs.current['empcep'].setValue(data.estabelecimento.cep.replace(/(\d{5})(\d{0,3})/, '$1-$2'));
                refs.current['empendereco'].setValue(camelize(data.estabelecimento.tipo_logradouro) + ' ' + camelize(data.estabelecimento.logradouro));
                refs.current['empendnumero'].setValue(data.estabelecimento.numero);
                refs.current['empbairro'].setValue(camelize(data.estabelecimento.bairro));
                refs.current['empmunicipio'].setValue(data.estabelecimento.cidade.nome);
                refs.current['empuf'].setValue(data.estabelecimento.estado.sigla);
            })
            .catch((error: any) => {
                console.log(error)
            })
            .finally(() => {
                refs.current['empcpfcnpj'].setMsgInfo('')
            })
    }
}

function changeCEP(_newValue: any, refs: any) {
    if (_newValue.length === 9) {
        getApiCEP(_newValue.replace('-', ''))
            .then((data: any) => {
                if (data.logradouro)
                    refs.current['empendereco'].setValue(data.logradouro);

                if (data.bairro)
                    refs.current['empbairro'].setValue(data.bairro);

                refs.current['empmunicipio'].setValue(data.localidade);
                refs.current['empuf'].setValue(data.uf);
            })
            .catch((error: any) => {
                console.log(error)
            })
    }
}

export const model_empresa = {
    icon: <FaBuildingCircleCheck />,
    name: 'Empresa',
    key: 'gruempid',
    api_get: '/v1/grupoempresacrm',
    api_post: '/v1/grupoempresacrm',
    description_field: 'empnome',
    inputs: [
        { id: 'gruid', label: 'Grupo', type: 'select', model: model_grupo, md: 3, required: true, disableEdit: true },
        { id: 'emptipopessoa', label: 'Tipo Pessoa', type: 'select', md: 3, options: options_tipo_pessoa, onAfterChange: changeTipoPessoa },
        { id: 'empcpfcnpj', label: 'CPF', type: 'text', md: 3, maxLength: 20, onAfterChange: changeCNPJ },
        { id: 'empie', label: 'IE', type: 'text', md: 3, maxLength: 20 },
        { id: 'empnome', label: 'Nome', type: 'text', md: 12, required: true, maxLength: 100 },
        { id: 'empcep', label: 'CEP', type: 'text', md: 3, maxLength: 10, model: 'cep', onAfterChange: changeCEP },
        { id: 'empendereco', label: 'Endereço', type: 'text', md: 7, maxLength: 200 },
        { id: 'empendnumero', label: 'Número', type: 'text', md: 2, maxLength: 10 },
        { id: 'empbairro', label: 'Bairro', type: 'text', md: 5, maxLength: 50 },
        { id: 'empmunicipio', label: 'Município', type: 'text', md: 5, maxLength: 50 },
        { id: 'empuf', label: 'UF', type: 'text', md: 2, maxLength: 2 },
        { id: 'empurllogo', label: 'URL Logo', type: 'text', md: 12, maxLength: 200 },
        { id: 'empobsfixarelatorio', label: 'Obs. Relatório', type: 'text', md: 12, maxLength: 5000, textarea: true, rows: 4 },
    ],
    base: {
    },
    size: 'lg'
}

export const model_municipio = {
    name: 'Município',
    key: 'muncodigo',
    api_get: '/v1/municipio',
    description_field: 'munnome',
    inputs: [],
    base: {}
}

export const model_cbo = {
    name: 'CBO',
    key: 'cbocodigo',
    api_get: '/v1/cbo',
    description_field: 'cbodescricao',
    inputs: [],
    base: {}
}

export const model_pais = {
    name: 'País',
    key: 'paiscodigo',
    api_get: '/v1/pais',
    description_field: 'paisnome',
    inputs: [],
    base: {}
}

export const model_jornada_trabalho = {
    name: 'Jornada Trabalho',
    key: 'jorntrabid',
    api_get: '/v1/jornadatrabalho',
    description_field: 'jorntrabdescricao',
    inputs: [],
    base: {}
}

export const model_col_conta_bancaria = {
    name: 'Conta Bancária Colaborador',
    key: 'colcbid',
    api_get: '/v1/colaboradorcontabancaria',
    description_field: 'colcbagencia',
    sub_description_field: 'colcbconta',
    inputs: [],
    base: {}
}

/*export const model_cargo = {
    name: 'Cargo',
    key: 'carid',
    api_get: '/v1/cargo',
    description_field: 'cardescricao',
    inactive_field: 'carativo',
    inputs: [],
    base: {}
}*/

export const model_colaborador = {
    icon: <FaPeopleGroup />,
    name: 'Colaboradores',
    key: 'colid',
    api_get: '/v1/colaboradorcrm',
    api_post: '/v1/colaborador',
    description_field: 'colnome',
    inputs: [
        { id: 'gruid', label: 'Grupo', type: 'select', model: model_grupo, md: 6, required: true, disableEdit: true },
        { id: 'colcpf', label: 'CPF', type: 'text', md: 3, maxLength: 20, model: 'cpf' },
        { id: 'colrg', label: 'RG', type: 'text', md: 3, maxLength: 20 },
        { id: 'colrgorgaoexpedicao', label: 'Órgão Expedidor RG', type: 'text', md: 3 },
        { id: 'colrgdataexpedicao', label: 'Data Expedição RG', type: 'date', md: 3 },
        { id: 'colnaturalidade', label: 'Naturalidade', type: 'text', md: 4 },
        { id: 'colnome', label: 'Nome', type: 'text', md: 12, required: true },
        { id: 'coladmissao', label: 'Admissão', type: 'date', md: 3 },
        { id: 'colnascimento', label: 'Nascimento', type: 'date', md: 3 },
        { id: 'colsexo', label: 'Sexo', type: 'select', md: 3, options: options_sexo },
        { id: 'coltelefone', label: 'Telefone', type: 'text', md: 3, maxLength: 20, model: 'phone' },
        { id: 'colsalarioinicial', label: 'Salário Inicial', type: 'number', md: 3 },
        { id: 'colemail', label: 'E-mail', type: 'text', md: 5, model: 'email' },
        { id: 'colcep', label: 'CEP', type: 'number', md: 2, maxLength: 10, model: 'cep-number' },
        { id: 'collogradouro', label: 'Endereço', type: 'text', md: 10, maxLength: 200 },
        { id: 'collogradouronumero', label: 'Número', type: 'text', md: 2, maxLength: 10 },
        { id: 'colbairro', label: 'Bairro', type: 'text', md: 4, maxLength: 50 },
        { id: 'muncodigo', label: 'Município', type: 'select', model: model_municipio, md: 4 },
        { id: 'paiscodigo', label: 'País', type: 'select', model: model_pais, md: 3 },
        { id: 'coldependentes', label: 'Dependentes', type: 'number', md: 3 },
        { id: 'colsindicalizado', label: 'Sindicalizado', type: 'select', options: options_sim_nao, md: 3 },
        { id: 'colcontribuicaosindical', label: 'Contribuição Sindical', type: 'select', options: options_sim_nao, md: 3 },
        { id: 'colcoretinia', label: 'Cor/Etnia', type: 'select', options: options_cor_etinia, md: 3 },
        { id: 'colestadocivil', label: 'Estado Civil', type: 'select', options: options_estadocivil, md: 3 },
        { id: 'colescolaridade', label: 'Escolaridade', type: 'select', options: options_escolaridade, md: 3 },
        { id: 'coldeficiencia', label: 'Deficiência', type: 'select', options: options_deficiente, md: 3 },
        { id: 'coloutradeficiencia', label: 'Ourta Deficiência', type: 'text', md: 3 },
        { id: 'colnomepai', label: 'Nome do Pai', type: 'text', md: 12 },
        { id: 'colnomemae', label: 'Nome da Mãe', type: 'text', md: 12 },
        { id: 'colnomeconjuge', label: 'Cônjuge', type: 'text', md: 12 },
        { id: 'cbocodigo', label: 'CBO', type: 'select', model: model_cbo, md: 12 },
        { id: 'jorntrabid', label: 'Jornada de Trabalho', type: 'select', model: model_jornada_trabalho, md: 6 },
        //{ id: 'carid', label: 'Cargo', type: 'select', model: model_cargo, md: 6 },
        { id: 'colcbid', label: 'Conta Bancária', type: 'select', model: model_col_conta_bancaria, md: 6 },
        { id: 'colobservacao', label: 'Observação', type: 'text', md: 12 },

    ],
    base: {
        colativo: 1
    },
    size: 'lg'
}

export const model_valores_complementares = {
    icon: <HiOutlineSwitchHorizontal />,
    name: 'Valores Complementares',
    key: 'valid',
    description_field: 'valnome',
    inactive_field: 'valativo',
    api_get: '/v1/valorescomplementarescrm',
    api_post: '/v1/valorescomplementarescrm',
    inputs: [
        { id: 'valid', label: 'Código', type: 'text', md: 12, disabled: true, required: true },
        { id: 'valnome', label: 'Nome', type: 'text', md: 12, required: true, maxLength: 50 },
        { id: 'valacao', label: 'Ação', type: 'select', options: options_acao_valcomplementares, md: 6, required: true },
        { id: 'valformato', label: 'Formato', type: 'select', options: options_formato_valcomplementares, md: 6, required: true },
        { id: 'valsequencia', label: 'Sequência', type: 'number', md: 4, required: true },
        { id: 'valgrupo', label: 'Grupo', type: 'number', md: 4, required: true },
        { id: 'valvalorpadrao', label: 'Padrão', type: 'number', md: 4 },
        { id: 'valdescricao', label: 'Descrição', type: 'text', md: 12, maxLength: 5000, textarea: true, rows: 4 },
    ],
    base: {
        valativo: 1
    }
}

export const model_fornecedor = {
    name: 'Fornecedor',
    key: 'forid',
    description_field: 'fornome',
    inactive_field: 'forativo',
    api_get: '/v1/fornecedor',
    inputs: [],
    base: {}
}

export const model_imobilizado = {
    name: 'Imobilizado',
    key: 'imobid',
    description_field: 'imobdescricao',
    inactive_field: 'imobativo',
    api_get: '/v1/imobilizado',
    inputs: [],
    base: {}
}

export const model_produto_medida = {
    name: 'Medidas de Produto',
    inactive_field: 'prodmedativo',
    api_get: '/v1/produtomedidacrm',
    api_post: '/v1/produtomedidaonecrm',
    inputs: [
        { id: 'prodid', label: 'Produto', type: 'select', model: model_produto, md: 12, required: true },
        { id: 'cultmedcontador', label: 'Medida', type: 'select', model: model_cultura_medida, md: 12, required: true },
    ],
    base: {
        prodmedativo: 1
    }
}
