import styled from "styled-components";
import { ConsultaPadrao } from "../../components/ConsultaPadrao";
import { model_colaborador, options_cor_etinia, options_deficiente, options_escolaridade, options_estadocivil, options_sim_nao } from "../../models";
import { Col, Row } from "react-bootstrap";
import { ageCalculator, formatDate, formatMonetary } from "../../commons/utils";
import { ColaboradorInvalidacoes } from "../ColaboradorInvalidacoes";
import { useRef } from "react";
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import { LabelDataTable } from "../../components/LabelDataTable";
import { ButtonWhatsApp } from "../../components/ButtonWhatsApp";

const ContainerDetail = styled.div`
    font-size: 12px;
    padding: 0 1rem;
`
const LabelDetail = styled.div`
    background-color: rgba(0, 0, 0, .3);
    border-radius: 4px;
    margin: 5px 1rem 8px 0px;
    padding: 4px 10px;
`
const RowDetail = styled.div`
    margin: 0;
    padding-left: 5px;
    margin-bottom: 3px;
    user-select: none;
`;

const DivCaption = styled.div`
    display: inline-block;
    margin-right: 4px;
`

const DivValue = styled.div`
    display: inline-block;
    user-select: text;
`

export const details = (row: any) => {
    function ViewDados(label: string, value: any) {
        return (
            <RowDetail>
                <DivCaption>{label}</DivCaption>
                <DivValue>{value}</DivValue>
            </RowDetail>
        )
    }

    function getLabelByValue(options: any, value: any) {
        const option = options.find((option: any) => option.value === value);
        return option ? option.label : "";
    }

    return (
        <ContainerDetail>
            <Row>
                <Col className={"p-0"}>
                    <LabelDetail>ENDEREÇO</LabelDetail>
                    {ViewDados('CEP:', row.colcep)}
                    {ViewDados('Logradouro:', `${row.collogradouro} Nº ${row.collogradouronumero}`)}
                    {ViewDados('Bairro:', row.colbairro)}
                    {ViewDados('Município:', row.munnome)}
                </Col>
                <Col className={"p-0"}>
                    <LabelDetail>DADOS PESSOAIS</LabelDetail>
                    {ViewDados('Orgão Expedidor RG:', row.colrgorgaoexpedicao)}
                    {ViewDados('Data Expedição RG:', formatDate(row.colrgdataexpedicao))}
                    {ViewDados('Naturalidade:', row.colnaturalidade)}
                    {ViewDados('Nascimento:', `${formatDate(row.colnascimento)} (${ageCalculator(row.colnascimento)} anos)`)}
                    {ViewDados('Sexo:', row.colsexo === 0 ? 'Masculino' : row.colsexo === 1 ? 'Feminino' : 'Outro')}
                    {ViewDados('E-mail:', row.colemail)}
                    {ViewDados('Dependentes:', row.coldependentes)}
                    {ViewDados('Cor/Etnia:', getLabelByValue(options_cor_etinia, row.colcoretinia))}
                    {ViewDados('Estado Civil:', getLabelByValue(options_estadocivil, row.colestadocivil))}
                    {ViewDados('Escolaridade:', getLabelByValue(options_escolaridade, row.colescolaridade))}
                    {ViewDados('Deficiência:', getLabelByValue(options_deficiente, row.coldeficiencia))}
                    {ViewDados('Outra Deficiência:', row.coloutradeficiencia)}
                    {ViewDados('Pai:', row.colnomepai)}
                    {ViewDados('Mãe:', row.colnomemae)}
                    {ViewDados('Cônjuge:', row.colnomeconjuge)}
                </Col>
                <Col className={"p-0"}>
                    <LabelDetail>DADOS PROFISSIONAIS</LabelDetail>
                    {ViewDados('Salário Inicial:', formatMonetary(row.colsalarioinicial))}
                    {ViewDados('Sindicalizado:', getLabelByValue(options_sim_nao, row.colsindicalizado))}
                    {ViewDados('Contribuição Sindical:', getLabelByValue(options_sim_nao, row.colcontribuicaosindical))}
                    {ViewDados('Jornada de Trabalho:', row.jorntrabdescricao)}
                    {ViewDados('Conta Bancária:', (row.colcbagencia ? row.colcbagencia : '') + (row.colcbconta ? ' - ' + row.colcbconta : ''))}
                </Col>
            </Row>
        </ContainerDetail>
    );
};


export const Colaborador = () => {
    const refinvalidacoes = useRef<any>();
    const refdatatable = useRef<any>();

    const FormatTelefoneWhatsApp = (num: string) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{num}</span>
                {num && <ButtonWhatsApp num={num} />}
            </div>
        );
    };

    const columns = [
        { field: 'colnome', header: 'Nome' },
        { field: 'grudescricao', header: 'Grupo' },
        { field: 'colcpf', header: 'CPF' },
        { field: 'colrg', header: 'RG' },
        { field: 'coldatacadastro', header: 'Cadastro', body: (e: any) => formatDate(e.coldatacadastro) },
        { field: 'coladmissao', header: 'Admissão', body: (e: any) => formatDate(e.coladmissao) },
        { field: 'cbodescricao', header: 'CBO (Cargo)' },
        { field: 'coltelefone', header: 'Telefone', body: (e: any) => FormatTelefoneWhatsApp(e.coltelefone) },
        { field: 'colstatusid', header: 'Status', body: (e: any) => { return LabelDataTable(e.colstatusdescricao, e.colstatuscor || '#e4e6e8') } },
        { field: 'functions', style: { minWidth: '2rem', width: '2rem' } }
    ];

    function functions(row: any) {
        return [
            { click: () => refinvalidacoes.current.openModal(row), icon: <PlaylistRemoveIcon />, color: '#da5a33' },
        ];
    }

    function onSuccess() {
        refdatatable.current.refresh();
    }

    return (
        <>
            <ColaboradorInvalidacoes ref={refinvalidacoes} onSuccess={onSuccess} />
            <ConsultaPadrao ref={refdatatable} model={model_colaborador} columns={columns} details={details} functions={functions} sortField='coldatacadastro' sortOrder={-1} />
        </>
    )
} 