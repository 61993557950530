import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Modal } from "../../../../components/Modal";
import TuneIcon from '@mui/icons-material/Tune';
import { Col, Row } from "react-bootstrap";
import { useAuth } from "../../../../contexts/AuthProvider/useAuth";
import { ExSwitch } from "../../../../components/ExSwitch";

interface PermissionSwitchProps {
    isChecked: boolean;
    isOpacity: boolean;
    onChange: () => void;
}

export const GruposStatus = forwardRef((props: any, ref) => {
    const auth = useAuth();

    const refmodal = useRef<any>();

    const [statusProjetos, setStatusProjetados] = useState<any[]>([]);
    const [status, setStatus] = useState<any[]>([]);

    function openModal(_gruid: any, _usucodigo: any) {
        setStatusProjetados([]);
        setStatus([]);

        getDados(_gruid, _usucodigo);
        refmodal.current.openModal();
    }

    useImperativeHandle(ref, () => ({
        openModal,
    }));

    async function getDados(_gruid: any, _usucodigo: any) {
        try {
            var response: any = await auth.get('/v1/usuariogrupostatuspermissaodesc?gruid=' + _gruid + '&usucodigo=' + _usucodigo);

            setStatusProjetados(response.data);

            response = await auth.get('/v1/usuariogrupostatus?gruid=' + _gruid + '&usucodigo=' + _usucodigo);
            setStatus(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    async function updateStatus(_body: any) {
        let body = {
            gruid: _body.gruid,
            usucodigo: _body.usucodigo,
            statid: _body.statid,
            usugrustler: _body.usugrustler,
            usugrustincluir: _body.usugrustincluir,
            usugrustalterar: _body.usugrustalterar,
            usugrustexcluir: _body.usugrustexcluir,
        };

        try {
            await auth.post('/v1/usuariogrupostatus', body);
            await getDados(_body.gruid, _body.usucodigo);
        } catch (error) {
            console.log(error);
        }
    }

    const PermissionSwitch: React.FC<PermissionSwitchProps> = ({ isChecked, isOpacity, onChange }) => (
        <Col className="text-end" style={{ opacity: isOpacity ? '25%' : '100%' }}>
            <ExSwitch checked={isChecked} onChange={onChange} />
        </Col>
    );

    const renderGrupoStatus = (statusProjetados: any[]): JSX.Element[] => {
        return statusProjetados.map(_status => {
            let originStatus = status.find(status2 => status2.statid === _status.statid);

            if (!originStatus)
                originStatus = {
                    gruid: _status.gruid,
                    usucodigo: _status.usucodigo,
                    statid: _status.statid,
                    usugrustler: null,
                    usugrustincluir: null,
                    usugrustalterar: null,
                    usugrustexcluir: null
                }

            return (
                <div key={_status.statid} style={{ padding: '8px 0px', fontSize: '14px' }}>
                    <Row>
                        <Col><span>{_status.statdescricao}</span></Col>
                        <PermissionSwitch
                            isChecked={_status.usugrustler === 1}
                            isOpacity={!originStatus || originStatus?.usugrustler === null}
                            onChange={() => { updateStatus({ ...originStatus, usugrustler: (!originStatus || originStatus.usugrustler === null) ? 1 : originStatus.usugrustler === 1 ? 0 : null }) }}
                        />
                        <PermissionSwitch
                            isChecked={_status.usugrustincluir === 1}
                            isOpacity={!originStatus || originStatus?.usugrustincluir === null}
                            onChange={() => { updateStatus({ ...originStatus, usugrustincluir: (!originStatus || originStatus.usugrustincluir === null) ? 1 : originStatus.usugrustincluir === 1 ? 0 : null }) }}
                        />
                        <PermissionSwitch
                            isChecked={_status.usugrustalterar === 1}
                            isOpacity={!originStatus || originStatus?.usugrustalterar === null}
                            onChange={() => { updateStatus({ ...originStatus, usugrustalterar: (!originStatus || originStatus.usugrustalterar === null) ? 1 : originStatus.usugrustalterar === 1 ? 0 : null }) }}
                        />
                        <PermissionSwitch
                            isChecked={_status.usugrustexcluir === 1}
                            isOpacity={!originStatus || originStatus?.usugrustexcluir === null}
                            onChange={() => { updateStatus({ ...originStatus, usugrustexcluir: (!originStatus || originStatus.usugrustexcluir === null) ? 1 : originStatus.usugrustexcluir === 1 ? 0 : null }) }}
                        />
                    </Row>
                </div>
            );
        });
    };

    return (
        <Modal
            ref={refmodal}
            title='Configuração de Status'
            size={'xl'}
            icon={<TuneIcon />}
        >
            <div style={{ padding: '5px 0' }}>
                <Row style={{ fontWeight: 'bold', padding: '8px 0' }}>
                    <Col>Status</Col>
                    <Col className="text-end">Ler</Col>
                    <Col className="text-end">Incluir</Col>
                    <Col className="text-end">Alterar</Col>
                    <Col className="text-end">Excluir</Col>
                </Row>
                {renderGrupoStatus(statusProjetos)}
            </div>
            <div style={{ marginTop: '6px', fontSize: '12px' }}>
                <span>A permissão "ler" diz respeito apenas aos registros dos demais usuários. O usuário sempre poderá consultar os próprios registros.</span>
            </div>
        </Modal>
    );
});
