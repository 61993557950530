import React from 'react';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';

const Label = styled.div<{ textColor: string }>`
    text-align: center;
    height: auto;
    width: auto;
    color: ${(props) => props.textColor};
    font-weight: bold;
    padding: 1px;
    border-radius: 4px;
`;

export interface LabelDataTableProps {
    color: string;
    children?: React.ReactNode;
}

const InternalLabelDataTable: React.FC<LabelDataTableProps> = (props) => {
    const textColor = tinycolor(props.color).isDark() ? '#fff' : '#2A323D';

    return (
        <Label
            style={{ backgroundColor: props.color }}
            textColor={textColor}
            {...props}
        />
    );
};

export const LabelDataTable = (value: React.ReactNode, color: string) => {
    return <InternalLabelDataTable color={color}>{value}</InternalLabelDataTable>;
};
