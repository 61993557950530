import { ConsultaPadrao } from "../../components/ConsultaPadrao";
import { ExSwitch } from "../../components/ExSwitch";
import { model_movimentoestoque_campos } from "../../models";


export const MovimentoEstoqueCampos = () => {
    const columns = [
        { field: 'movestcamposequencia', header: 'Sequência' },
        { field: 'movestcamponome', header: 'Nome' },
        { field: 'tipmovdescricao', header: 'Tipo Movimento' },
        { field: 'produtodesc', header: 'Produto' },
        { field: 'tipmovstdescricao', header: 'A partir do status' },
        { field: 'movestcampoobrigatorio', header: 'Obrigatório', body: (e: any) => <ExSwitch checked={e.movestcampoobrigatorio === 1} /> },
        { field: 'movestcampoativo', header: 'Ativo' },
        { field: 'functions', style: { minWidth: '2rem', width: '2rem' } }
    ];

    return (
        <ConsultaPadrao model={model_movimentoestoque_campos} columns={columns} sortField="movestcamposequencia" />
    )
}