import { ConsultaPadrao } from "../../components/ConsultaPadrao";
import { ExSwitch } from "../../components/ExSwitch";
import { model_tipo_movimento } from "../../models";

export const TipoMovimento = () => {
    const columns = [
        { field: 'tipmovcontador', header: 'Código' },
        { field: 'tipmovdescricao', header: 'Descrição' },
        { field: 'tipmovacao', header: 'Ação' },
        { field: 'tipmovperda', header: 'Perda', body: (e: any) => <ExSwitch checked={e.tipmovperda === 1} /> },
        { field: 'tipmovinformapreco', header: 'Informa Preço', body: (e: any) => <ExSwitch checked={e.tipmovinformapreco === 1} /> },
        { field: 'tipmovfinanceiro', header: 'Gera Financeiro', body: (e: any) => <ExSwitch checked={e.tipmovfinanceiro === 1} /> },
        { field: 'tipmovativo', header: 'Ativo' },
        { field: 'functions', style: { minWidth: '2rem', width: '2rem' } }
    ];

    return (
        <ConsultaPadrao model={model_tipo_movimento} columns={columns} sortField="tipmovcontador" />
    )
}