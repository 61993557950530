import { ConsultaPadrao } from "../../components/ConsultaPadrao";
import { model_produto_medida } from "../../models";

export const ProdutoMedida = () => {
    const columns = [
        { field: 'proddescricao', header: 'Produto' },
        { field: 'cultmeddescricao', header: 'Medida' },
        { field: 'functions', style: { minWidth: '2rem', width: '2rem' } }
    ];

    return (
        <ConsultaPadrao model={model_produto_medida} columns={columns} sortField="proddescricao" />
    )
}