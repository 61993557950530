import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Modal } from "../../components/Modal";
import { useAuth } from "../../contexts/AuthProvider/useAuth";
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import { Col, Row } from "react-bootstrap";
import { ExCheckBox } from "../../components/ExCheckBox";
import { Input } from "../../components/Input";
import { formatDate, formatMonetary, toastError } from "../../commons/utils";
import { toast } from "react-toastify";
import { ButtonWhatsApp } from "../../components/ButtonWhatsApp";
import { options_cor_etinia, options_deficiente, options_escolaridade, options_estadocivil, options_sim_nao } from "../../models";

export const ColaboradorInvalidacoes = forwardRef((props: any, ref) => {
    const auth = useAuth();

    function getLabelByValue(options: any, value: any) {
        const option = options.find((option: any) => option.value === value);
        return option ? option.label : "";
    }

    const columns = [
        { key: 'coladmissao', description: 'Admissão', type: 'date' },
        { key: 'colbairro', description: 'Bairro' },
        { key: 'cbocodigo', description: 'CBO (Cargo)', field: 'cbodescricao' },
        { key: 'colcep', description: 'CEP' },
        { key: 'colnomeconjuge', description: 'Cônjuge' },
        { key: 'colcbid', description: 'Conta Bancária', field: 'colcbagencia', subfield: 'colcbconta' },
        { key: 'colcoretinia', description: 'Cor/Etnia', body: (etnia: any) => getLabelByValue(options_cor_etinia, etnia) },
        { key: 'colcontribuicaosindical', description: 'Contribuição Sindical', body: (cont_sindical: any) => getLabelByValue(options_sim_nao, cont_sindical) },
        { key: 'colcpf', description: 'CPF' },
        { key: 'colrgdataexpedicao', description: 'Data Expedição RG', type: 'date' },
        { key: 'coldeficiencia', description: 'Deficiência', body: (deficiencia: any) => getLabelByValue(options_deficiente, deficiencia) },
        { key: 'coldependentes', description: 'Dependentes' },
        { key: 'colescolaridade', description: 'Escolaridade', body: (escolaridade: any) => getLabelByValue(options_escolaridade, escolaridade) },
        { key: 'colestadocivil', description: 'Estado Civil', body: (estado_civil: any) => getLabelByValue(options_estadocivil, estado_civil) },
        { key: 'colemail', description: 'E-mail' },
        { key: 'gruid', description: 'Grupo', field: 'grudescricao' },
        { key: 'jorntrabid', description: 'Jornada de Trabalho', field: 'jorntrabdescricao' },
        { key: 'collogradouro', description: 'Logradouro' },
        { key: 'collogradouronumero', description: 'Número' },
        { key: 'muncodigo', description: 'Município', field: 'munnome' },
        { key: 'colnascimento', description: 'Nascimento', type: 'date' },
        { key: 'colnaturalidade', description: 'Naturalidade' },
        { key: 'colnome', description: 'Nome' },
        { key: 'colnomemae', description: 'Nome da Mãe' },
        { key: 'colnomepai', description: 'Nome do Pai' },
        { key: 'colrgorgaoexpedicao', description: 'Orgão Expedidor RG' },
        { key: 'coloutradeficiencia', description: 'Outra Deficiência' },
        { key: 'paiscodigo', description: 'País', field: 'paisnome' },
        { key: 'colrg', description: 'RG' },
        { key: 'colsalarioinicial', description: 'Salário Inicial', body: (salario: any) => { return formatMonetary(salario) } },
        { key: 'colsindicalizado', description: 'Sindicalizado', body: (sindicalizado: any) => getLabelByValue(options_sim_nao, sindicalizado) },
        { key: 'colsexo', description: 'Sexo', body: (sexo: any) => { return sexo === 0 ? 'Masculino' : sexo === 1 ? 'Feminino' : 'Outro' } },
        { key: 'coltelefone', description: 'Telefone', type: 'phone' },
    ];

    const refmodal = useRef<any>();

    const [data, setData] = useState<any>();
    const [enabledInputs, setEnabledInputs] = useState<{ [key: number]: boolean }>({});

    function openModal(_data: any) {
        setData(_data);
        setEnabledInputs({});
        refmodal.current.openModal();
    }

    function handleCheckboxChange(index: number) {
        setEnabledInputs((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    }

    function handleSuccess() {
        const selectedOptions = columns
            .map((column, index) => {
                if (enabledInputs[index]) {
                    let fieldValue = data[column.key];
                    const comentario = (document.getElementById(`input-${index}`) as HTMLInputElement)?.value || '';

                    return {
                        colid: data.colid,
                        colinvcampo: column.key,
                        colinvvalor: fieldValue !== null && fieldValue !== undefined ? (typeof fieldValue === 'object' ? JSON.stringify(fieldValue) : fieldValue) : "",
                        colinvcomentario: comentario.trim()
                    };
                }
                return null;
            })
            .filter(Boolean);

        auth.post('/v1/colaboradorinvalidacoes', selectedOptions)
            .then(() => {
                toast.success('Registro invalidado com sucesso.');
                props.onSuccess();
                refmodal.current.closeModal();
            })
            .catch((error: any) => {
                toastError(error)
            })
    }

    useImperativeHandle(ref, () => ({
        openModal,
    }));

    return (
        <Modal
            ref={refmodal}
            title={'Invalidar Informações'}
            icon={<PlaylistRemoveIcon />}
            size={'lg'}
            success={handleSuccess}
        >
            {data && (
                <div>
                    {columns.map((column, index) => {
                        let fieldValue = column.field ? data[column.field] : data[column.key];

                        if (column.subfield)
                            fieldValue = fieldValue + ' - ' + data[column.subfield]

                        return (
                            <div key={index} style={{ marginTop: '5px solid #fff' }}>
                                <Row style={{ fontSize: '14px', padding: '8px 0', display: 'flex', alignItems: 'center' }}>
                                    <Col md={1} style={{ display: 'flex', alignItems: 'center' }}>
                                        <ExCheckBox defaultValue={!!enabledInputs[index]} onClick={() => handleCheckboxChange(index)} />
                                    </Col>
                                    <Col md={3} style={{ display: 'flex', alignItems: 'center' }}>{column.description}</Col>
                                    <Col md={8} style={{ display: 'flex', alignItems: 'center' }}>
                                        {column.type === 'date' ? formatDate(fieldValue) : column.body ? column.body(fieldValue) : fieldValue}
                                        {column.type === 'phone' && <ButtonWhatsApp num={fieldValue} />}
                                    </Col>

                                    {enabledInputs[index] && (
                                        <div>
                                            <Col md={12} style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                                                <Col md={2}>Comentário:</Col>
                                                <Col md={10}> <Input id={`input-${index}`} type='text' /></Col>
                                            </Col>
                                            <div style={{ margin: '5px 40px', borderBottom: '1px solid #686868' }} />
                                        </div>
                                    )}
                                </Row>
                            </div>
                        );
                    })}
                </div>
            )}
        </Modal>
    );
});

